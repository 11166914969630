<!-- V 21.12.2021 -->

<template>
  <div class="">
    <div class="flex flex-col items-center text-left w-full">
      <div
        v-if="open"
        class="z-10 fixed top-0 bottom-0 left-0 right-0"
        @click="openSelect"
      />
      <div class="w-full">
        <div class="flex flex-col items-center relative">
          <!-- Select -->
          <div class="w-full z-10" @click="openSelect">
            <div
              :class="[
                'flex items-center border border-gray-200 bg-white rounded-t',
                { 'bg-gray-50': disabled },
                {'rounded-b': !open}
              ]"
            >
              <!-- Opción seleccionada -->
              <div class="flex-1 ml-2 pr-3 truncate">
                <input
                  v-if="selected || !canAll"
                  :value="
                    selected
                      ? options.find((o) => o.value == selected)?.text
                      : null
                  " 
                  class="
                    border-0
                    outline-none
                    ring-0
                    bg-transparent
                    w-full
                    cursor-pointer
                    inputPreview
                  "
                  :placeholder="placeholder"
                  :required="required"
                  data-readonly
                  onkeypress="return false;"
                />
                <span v-else-if="canAll">Todos</span>
              </div>

              <div
                class="
                  text-gray-300
                  w-5
                  border-l
                  flex
                  items-center
                  border-gray-200
                "
              >
                <button
                  :class="[
                    { 'rotate-180': !open },
                    'transform flex justify-center items-center cursor-pointer w-5 h-5 text-gray-600 outline-none focus:outline-none',
                  ]"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-up w-4 h-4"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!-- Opciones -->
          <div
            v-show="open"
            class="z-99 w-auto absolute left-0"
            style="top: 1.8rem; min-width: 100%"
          >
            <!-- Buscador -->
            <div
              v-if="canSearch"
              class="
                p-1
                border
                bg-white
                w-full
                relative
                flex
                items-center
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 text-gray-400 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
              <input
                type="text"
                class="
                  flex-1
                  w-full
                  h-full
                  border-none
                  active:outline-none
                  focus:outline-none
                "
                ref="inputBusqueda"
                autofocus
                placeholder="Buscar..."
                v-model="searchBy"
              />
              <div
                v-if="searchBy"
                @click="searchBy = null"
                class="hover:text-red-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="
                    feather feather-x
                    cursor-pointer
                    text-gray-400
                    hover:text-main
                    rounded-full
                    w-3
                    h-3
                    ml-2
                  "
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>

            <!-- Listado -->
            <div
              class="
                max-h-56
                shadow-xl
                bg-white
                border-l border-b border-r
                z-40
                w-full
                rounded-b
                max-h-select
                overflow-y-auto
                select-none
              "
            >
              <div v-if="optionsComp.length === 0" class="text-gray-500 p-2">
                No hay resultados para mostrar.
              </div>
              <!-- Todos -->
              <div
                v-if="canAll && !searchBy"
                :class="[
                  'flex flex-col w-full bg-white list-item hover:bg-gray-200',
                ]"
                @click="clickOption('')"
              >
                <div
                  class="
                    cursor-pointer
                    w-full
                    border-gray-100
                    rounded-t
                    border-b
                    hover:bg-teal-100
                  "
                >
                  <div
                    class="
                      flex
                      w-full
                      items-center
                      border-transparent border-l-2
                      relative
                      hover:border-teal-100
                    "
                  >
                    <div class="w-full items-center flex">
                      <div class="mx-2 leading-6">Todos</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Row Opciones -->
              <div
                v-for="(opcion, index) in optionsComp"
                :key="index"
                :class="[
                  'flex flex-col w-full bg-white list-item hover:bg-gray-200',
                ]"
                @click="clickOption(opcion.value)"
              >
                <div
                  class="
                    py-2
                    cursor-pointer
                    w-full
                    border-gray-100
                    rounded-t
                    border-b
                    hover:bg-blue-50
                  "
                >
                  <div
                    class="
                      flex
                      w-full
                      items-center
                      border-transparent border-l-2
                      relative
                      hover:border-blue-50
                    "
                  >
                    <div class="w-full items-center flex">
                      <div class="mx-2 leading-4">
                        {{ opcion.text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, ref, toRef } from "vue";

export default defineComponent({
  emits: ["update:selected"],
  props: {
    options: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every((v) => {
          return (v.value || v.value == 0) && v.text;
        });
      },
      default: () => []
    },
    selected: {
      type: [String, Number, null],
      required: true,
    },
    canSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    canAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    required:{
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, { emit }) {
    let searchBy = ref(null);
    let open = ref(false);
    let disabled = toRef(props, "disabled");

    const optionsComp = computed(() => {
      let options = props.options;
      if (searchBy.value) {
        options = options.filter((o) => {
          let regex = new RegExp(`${searchBy.value.trim().toLowerCase()}`);
          let opcion = o.text ? o.text.trim().toLowerCase() : o.text;
          return regex.test(opcion);
        });
      }
      return options;
    });

    const widthValue = () => {
      return props.fullWidth
        ? "width: 100%"
        : props.canSearch
        ? "width: 8rem"
        : "width:4rem";
    };

    const clickOption = (value) => {
      open.value = false;
      emit("update:selected", value);
    };

    let inputBusqueda = ref(null);
    const openSelect = () => {
      if (!disabled.value) {
        open.value = !open.value;
      }

      // Autofocus al input de búsqueda
      nextTick(() => {
        if (inputBusqueda.value) {
          inputBusqueda.value.focus();
        }
      });
    };
    return { searchBy, optionsComp, open, clickOption, widthValue, openSelect, inputBusqueda };
  },
});
</script>

<style scoped>
input.inputPreview {
  color: transparent;
  text-shadow: 0 0 0 black;
}
input.inputPreview:focus {
  outline: none;
}
.z-99 {
  z-index: 99;
}

input[data-readonly] {
  pointer-events: none;
}
</style>