<script setup>
import axios from "axios";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { apiGetProvincias, apiGetFacturas, apiGetLogin } from "@/utils/endpoints";
import Select from "../components/elements/Select.vue";
import { showToast } from "../utils/functions";
import router from "@/router/index";

const store = useStore();
let datosUsuario = ref(store.state.user);

const campos = ref([
    { id: 'nombre', title: 'Nombre', model: "", type: "input" },
    { id: 'apellido', title: 'Apellido', model: "", type: "input" },
    { id: 'direccion', title: 'Direccion', model: "", type: "input" },
    { id: 'provincia', title: 'Provincia', model: "", type: "select" },
    { id: 'ciudad', title: 'Ciudad', model: "", type: "input" },
    { id: 'cp', title: 'Código Postal', model: "", type: "input" },
    { id: 'email', title: 'Email', model: "", type: "input" },
    { id: 'telefono', title: 'Teléfono', model: "", type: "input" },
    { id: 'factura', title: 'Tipo de factura', model: "", type: "select" },
    { id: 'dni', title: 'DNI/CUIT/CUIL', model: "", type: "input" },
    { id: 'comentarios', title: 'Comentarios', model: "", type: "input" },
    { id: 'newAccount', title: '', model: false, type: "password" },
    { id: 'password', title: '', model: "", type: "" },
    { id: 'password2', title: '', model: "", type: "" },
    { id: 'provinciastr', title: '', model: "", type: "" },
    { id: 'facturastr', title: '', model: "", type: "" },
    { id: 'user_id', title: '', model: "", type: "" },
]);
Object.assign(campos.value, datosUsuario.value);

const login = ref([
    { id: 'email', title: 'Email', model: "", type: "input" },
    { id: 'password', title: 'Contraseña', model: "", type: "password" },
]);



const goToCheckout = () => {
    let newAccount = campos.value.find(campo => campo.id == 'newAccount').model;
    let password = campos.value.find(campo => campo.id == 'password').model;
    let password2 = campos.value.find(campo => campo.id == 'password2').model;

    if (newAccount && password !== password2) {
        showToast('Las contraseñas no coinciden', "danger")
    } else {
        store.commit("setUser", campos.value);
        router.push({ name: "Checkout" })
    }
}

let loginError = ref("");
const applyLogin = () => {
    console.log("login")
    axios.post(apiGetLogin(), {
        email: login.value[0].model,
        password: login.value[1].model,
    }).then(data => {
        let parser = {
            direccion: 'address',
            ciudad: 'city',
            dni: 'dni',
            email: 'email',
            nombre: 'firstname',
            apellido: 'lastname',
            provincia: 'province_code',
            telefono: 'telephone',
            factura: 'invoice_type_id',
            comentarios: 'order_comments',
            cp: 'zip',
            user_id: 'user_id'
        }
        campos.value.forEach(c => {
            c.model = data.data[parser[c.id]];
        })
        store.commit("setUser", campos.value);
        router.push({ name: "Checkout",params: { id: '2'} })
    }).catch(err => {
        console.log(err);
        loginError.value = "Email o contraseña incorrectos"
    })
}

let selectOptions = reactive({
    provincia: [],
    factura: []
})
axios.get(apiGetProvincias()).then(data => {
    let provinciasArr = data.data.map(provincia => {
        return {
            value: provincia.province_code,
            text: provincia.province
        }
    })
    selectOptions['provincia'].push(...provinciasArr)
})
axios.get(apiGetFacturas()).then(data => {
    let facturasArr = data.data.map(factura => {
        return {
            value: factura.invoice_type_id,
            text: factura.invoice_type
        }
    })
    selectOptions['factura'].push(...facturasArr)
})

let accedeOpen = ref(false);
</script>

<template>
    <div class="container max-w-screen-lg p-4 mx-auto my-6">
        <h1 class="font-bold text-4xl">Tus datos</h1>
        <h3 class="font-xl">
            <!-- <span
                class="text-blue-700 cursor-pointer hover:underline"
                @click="accedeOpen = !accedeOpen"
            >Accedé</span>-->
            Accedé con tu usuario o completá los siguientes datos para realizar la compra
        </h3>

        <!-- Login -->
        <div v-if="!accedeOpen">
            <div class="w-full sm:w-1/3 mt-2 p-4 border rounded flex flex-col bg-gray-50">
                <form @submit.prevent="applyLogin">
                    <span v-if="loginError" class="text-red-700">{{ loginError }}</span>
                    <span class="font-bold text-2xl flex flex-1 justify-center">Acceder</span>
                    <div v-for="campo in login" :key="campo.id" class="flex flex-col">
                        <div class="font-bold">{{ campo.title }}</div>
                        <input
                            :type="campo.type"
                            required
                            class="w-2/3 pl-0.5 border rounded"
                            v-model="campo.model"
                        />
                    </div>
                    <button
                        @click="applyLogin"
                        type="submit"
                        class="mt-4 p-2 bg-main hover:bg-main-darker transition duration-400 text-white rounded"
                    >Ingresar</button>
                </form>
            </div>

            <div class="mt-5 border-b h-1 w-full"></div>
        </div>

        <!-- Registro -->
        <form @submit.prevent="goToCheckout">
            <div class="mt-6 p-4 border rounded text-lg bg-gray-50">
                <span class="font-bold text-2xl flex flex-1 justify-start">Completá tus datos</span>
                <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3">
                    <div v-for="campo in campos" :key="campo.id">
                        <div v-if="campo.type">
                            <div class="font-bold">{{ campo.title }}</div>
                            <input
                                v-if="campo.type == 'input'"
                                type="text"
                                required
                                :name="campo.id"
                                class="w-2/3 pl-0.5 border rounded"
                                v-model="campo.model"
                            />
                            <Select
                                v-if="campo.type == 'select'"
                                :options="selectOptions[campo.id]"
                                v-model:selected="campo.model"
                                :required="false"
                                class="w-2/3"
                            ></Select>

                            <!-- Crear cuenta -->
                            <div
                                v-if="campo.type == 'password'"
                                class="h-full flex flex-col justify-center"
                            >
                                <div class="flex items-center gap-1">
                                    <input
                                        type="checkbox"
                                        id="nuevaCuenta"
                                        v-model="campo.model"
                                        class="w-4 h-4 cursor-pointer"
                                    />
                                    <label
                                        for="nuevaCuenta"
                                        class="cursor-pointer select-none"
                                    >Crear una cuenta de usuario para futuras compras</label>
                                </div>

                                <div v-if="campo.model">
                                    <div>
                                        <div class="font-bold">Ingresa una contraseña</div>
                                        <input
                                            type="password"
                                            required
                                            v-model="campos.find(c => c.id === 'password').model"
                                            class="w-2/3 pl-0.5 border rounded"
                                        />
                                    </div>
                                    <div>
                                        <div class="font-bold">Confirma la contraseña</div>
                                        <input
                                            type="password"
                                            required
                                            v-model="campos.find(c => c.id === 'password2').model"
                                            class="w-2/3 pl-0.5 border rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4 flex flex-col">
                <div>
                    <button
                        type="submit"
                        class="p-2 bg-main hover:bg-main-darker transition duration-400 text-white rounded"
                    >Continuar</button>
                </div>
                <span class="text-xs">Podrás revisar tus datos antes de realizar el pago online</span>
            </div>
        </form>
    </div>
</template>